export { matchers } from './client-matchers.js';

			export const nodes = [() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15')];

			export const server_loads = [];

			export const dictionary = {
	"(main)": [11,[3]],
	"(auth)/recover": [4,[2]],
	"(auth)/signin": [8,[2]],
	"(auth)/signup": [9,[2]],
	"(auth)/verify-email": [10,[2]],
	"(main)/billing": [13,[3]],
	"(main)/support": [15,[3]],
	"(auth)/recover/password": [5,[2]],
	"(auth)/recover/success": [7,[2]],
	"(auth)/recover/password/success": [6,[2]],
	"(main)/addon/confirm_auth/[t]": [12,[3]],
	"(main)/render/[render_id]": [14,[3]]
};

			export const hooks = {
				handleError: (({ error }) => { console.error(error); return { message: 'Internal Error' }; }),
			};